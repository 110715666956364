<template>
  <div>
    <SettingsNavigation :links="nestedLinks" />

    <div class="Settings__Children">
      <Authorize :permission="$route.meta.permission || true">
        <router-view />
      </Authorize>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Authorize from '@/components/core/Authorize.vue'
import SettingsNavigation from '@/pages/settings/components/SettingsNavigation.vue'

export default {
  components: {
    Authorize,
    SettingsNavigation
  },

  computed: {
    ...mapGetters(['activeBrand', 'currentUser', 'fullName', 'isFreemiumUser']),

    nestedLinks () {
      return [
        {
          label: this.$t('pages.settings.personal.navigation.preferences'),
          to: { name: 'settings.personal.preferences' },
          icon: 'user'
        },
        {
          label: this.$t('pages.settings.personal.navigation.email'),
          to: { name: 'settings.personal.email' },
          icon: 'envelope'
        },
        {
          label: this.$t('pages.settings.personal.navigation.brands'),
          to: { name: 'settings.personal.brands' },
          icon: 'fire'
        },
        {
          label: this.$t('pages.settings.personal.navigation.media_notifications'),
          to: { name: 'settings.personal.media_notifications' },
          icon: 'notifications'
        }
      ]
    }
  }
}
</script>
